import React from 'react';

import '../styles/buy_now.scss';

const BuyNow = ({to}) => {
    return (
        <div className="buy-now-link-container">
            <a href={`${to}`} target="_blank" className="buy-now-link">
                &gt;&gt; buy it &lt;&lt;
            </a>
        </div>
    )
}

export default BuyNow;