import React from 'react';
import { graphql, Link } from 'gatsby';

import BuyNow from '../components/buy_now'
import Layout from '../components/layout';
import SEO from '../components/seo';

import '../styles/shop_item.scss';

export default function ShopItemTemplate({
    data,
}) {
    const { markdownRemark } = data;
    const { frontmatter, html } = markdownRemark;

    return (
        <Layout>
            <SEO title={ frontmatter.title } />
            <div className="headerText">
                shop
            </div>
            <div className="shop-content">
                <div className="shop-item-content">
                    <div className="title-row">
                        <div className="back-link-container">
                            <Link to='/shop' className="back-link">&lt; back</Link>
                        </div>
                        <div className="product-title">
                            {frontmatter.title}
                        </div>
                    </div>
                    <BuyNow to={frontmatter.shop_link} key="top_link" />
                    <div dangerouslySetInnerHTML={{ __html: html }}></div>
                    <BuyNow to={frontmatter.shop_link} key="bottom_link" />
                    <div className="footnote">
                        Farm information and images from&nbsp;
                        {frontmatter.supplier ? frontmatter.supplier : `Sweet Maria's / Coffee Shrub`}
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export const pageQuery = graphql `
    query($slug: String!) {
        markdownRemark(frontmatter: { slug: { eq: $slug } }) {
            html
            frontmatter {
              slug
              title
              shop_link
              supplier
            }
        }
    }`